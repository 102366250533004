<template>
    <div id="pageList" class="FleetList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车队名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入车队名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">结算周期：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('jsType',el)" v-model="pageList.queryParam.jsType" clearable placeholder="请选择结算周期" style="width: 100%" @change="(val)=>{selectOnChange(val,'jsType')}">
                            <el-option v-for="item in comboSelect({comboId:'jsType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">所属承运商：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.cysId = validForbid(e)" placeholder="请输入承运商进行模糊匹配查询..." v-model="pageList.queryParam.cysId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所在城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择所在城市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>

                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">负责人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.contacts = validForbid(e)" placeholder="请输入负责人进行模糊匹配查询..." v-model="pageList.queryParam.contacts" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系电话：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.telephone = validForbid(e)" placeholder="请输入联系电话进行模糊匹配查询..." v-model="pageList.queryParam.telephone" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">卡号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.cardNum = validForbid(e)" placeholder="请输入卡号进行模糊匹配查询..." v-model="pageList.queryParam.cardNum" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">开户行：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.khh = validForbid(e)" placeholder="请输入开户行进行模糊匹配查询..." v-model="pageList.queryParam.khh" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">登录账号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入登录账号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="车队名称"/>
                <el-table-column prop="F_JOIN_TYPE" label="车队类别"/>
                <el-table-column prop="F_YQM" label="邀请码"/>
                <el-table-column prop="F_CHANNEL_ID" label="所属渠道"/>
                <el-table-column prop="F_JS_TYPE" label="结算周期"/>
                <el-table-column prop="F_CYS_ID" label="所属承运商"/>
                <el-table-column prop="F_CITY_ID" label="所在城市"/>
                <el-table-column prop="F_CONTACTS" label="负责人"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_CARD_NUM" label="卡号"/>
                <el-table-column prop="F_KHH" label="开户行"/>
                <el-table-column prop="F_CODE" label="登录账号"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import FleetCard from './FleetCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "FleetList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                pageList: {
                    queryParam: {
                       name:"",
                       cysId:"", 
                       jsType:"", 
                       contacts:"", 
                       telephone:"", 
                       cardNum:"", 
                       khh:"", 
                       code:"", 
                       status:"", 
                       flag:"", 
                       cityId:"" 
                    },
                    modelComp: FleetCard,
                    modelMethod: "/fleet/pageData"
               }
            })
            onMounted(async ()=>{
                await buildCityData();
            })
            const selectOnChange=async(newValue,selectId)=>{

            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'jsType'==params.comboId){
                        return [{value:0,label:'日结'},{value:1,label:'月结'}]
                    }
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })

            const buildCityData=async ()=>{
                dataObj.cityData = await utils.$$api.getCitiesByChannelId({});
            }
            return{
                ...toRefs(dataObj),comboSelect,selectOnChange
            }
        }
    });
</script>

<style scoped>
    .FleetList{
        width: 100%;
    }
</style>